import React,{useState,useMemo} from 'react';
import {fetchTracking} from '../../utils/fetchApi';
import classes from './tracking.module.css';
import TopPanel from '../../components/TopPanel';
import TrackingItem from './trackingItem';
import TrackingStep from './trackingStep';
import { sortByDate } from '../../utils/functions';
import Loader from '../../components/Loader';
import Send from '../../assets/images/send.svg';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {Collapse} from 'react-collapse';
import { ChevronDown,ChevronUp } from "react-feather";

export default function Tracking(props){
    const [open,setOpen] = useState(false)
    const [tracking,setTracking] = useState(null);
    const [order,setOrder] = useState(null);
    const [loading,setLoading] = useState(false);
    const [searched,setSearched] = useState(false);
    const [typeOrder,setTypeOrder] = useState(null);
    const [numOrder,setNumOrder] = useState(null);
    const [detailCommandeItems,setdetailCommandeItems] = useState([]);
    const [statusTracking, setStatusTracking] = useState({
        prise_compte: {
            status: false,
            date: null
        },
        rdv: {
            status: false,
            date: null
        },
        prep: {
            status: false,
            date: null
        },
        expedition: {
            status: false,
            date: null
        },
        livraison: {
            status: false,
            date: null
        },
        aar: {
            status: false,
            date: null
        }
    });
    
    const {num_cmd} = useParams();
    
    const getTracking = (id) => {
        return new Promise(async(resolve)=>{
            let getData = await fetchTracking(id);
            resolve(getData);
        })
    }

    const handleSearch = async(e,value=null, isSearched = false) =>{
        setLoading(true);
        e?.preventDefault(); 
        
        let idTrans = num_cmd;
        // setNumOrder(searchData("ID_CMD_TRANS", order))

        let trackings = [];
        let data = await getTracking(idTrans);
        if (data && data.success) {
            var transTrack = data.reports
            setOrder(data.order)
            setNumOrder(data?.order?.numCmdClient)
        } else {
            setLoading(false)
            return;
        }

        
        
        transTrack = transTrack.filter(e => e.code_evt === "CRE" && e.display || e.code_evt === "PRE" ||  e.code_evt === "AAR" || e.code_evt === "DIF" || e.code_evt === "VAL" || e.code_evt === "LIV" && e.display === true)
        trackings = [...trackings, ...transTrack]
        //TODO REORGNAISER PAR DATE
        setSearched(!isSearched);
        // setLoading(false);
        trackings = sortByDate(trackings, 'DATE_SAISIE')
        setTracking(trackings);

        const type_cmd_log = order?.sttiFlatInformation?.infoQuai;
        const getProducts = order?.sttiFlatArticles
        const type_cmd = order?.idType;
        if (getProducts) {
            //setdetailCommandeItems(getProducts.split(' + '))
        }
        let getStatus = statusTracking;

        if (type_cmd_log === "MES") {
            setTypeOrder('mes')
        } else if (type_cmd_log == "LAD" || type_cmd_log == "SMA") {
            setTypeOrder('lad-sma')
        } else {
            setTypeOrder('trans')
        }

        for (let track of trackings) {
            let getEVT = track.code_evt
            let typeEVT = track.type_evt
            if (getEVT) {
                if (getEVT === "CRE") {
                    getStatus.prise_compte.status = true
                    getStatus.prise_compte.date = track.date_situation
                    getStatus.prise_compte.date_saisie = track.date_saisie
                } else if (getEVT === "PRE") {
                    getStatus.prep.status = true
                    getStatus.prep.date = track.date_situation
                    getStatus.prep.date_saisie = track.date_saisie
                } else if (getEVT === "DIF") {
                    getStatus.rdv.status = true
                    getStatus.rdv.date = track.date_situation
                    getStatus.rdv.date_saisie = track.date_saisie
                    getStatus.rdv.refused = track.code_justification === 'DAF'
                } else if (getEVT === "VAL") {
                    getStatus.expedition.status = true
                    getStatus.expedition.date = track.date_situation
                    getStatus.expedition.date_saisie = track.date_saisie
                }
                else if (getEVT === "LIV") {
                    getStatus.livraison.status = true
                    getStatus.livraison.date = track.date_situation
                    getStatus.livraison.date_saisie = track.date_saisie
                } else if (getEVT === "AAR") {
                    getStatus.aar.status = true
                    getStatus.aar.date = track.date_situation
                    getStatus.aar.date_saisie = track.date_saisie
                }
            }
        }

        setStatusTracking(getStatus);
        setLoading(false);

    }
    
    const Tracking = useMemo(() => {
        if(tracking){
            let sortTracking = sortByDate(tracking,'DATE_SAISIE','DESC')
            return(
                <>
                    <div>
                        <TrackingStep statusTracking={statusTracking} typeOrder={typeOrder} />
                    </div>
                    <div>
                        {sortTracking.map( (e,index) => (
                            <TrackingItem 
                                item={e} 
                                key={`tracking-${index}`} 
                                isLast={index+1 === sortTracking.length}
                                order={order}
                            />
                        ))}                    
                    </div>
                </>
            );
        } else {
            return searched ? <p>Pas de résultats, essayez avec un autre numéro </p> : null
        }
        
    },[tracking, typeOrder, searched])

    useEffect(()=>{
        if(num_cmd){
            handleSearch(null,num_cmd,true)
        }
    }, [num_cmd]);

    useEffect(() => {
        if(!props.isLog && !num_cmd){
            props.logout(true, false);
        }
    }, []);

    return(
        <div className={classes.root}>
            {
                !num_cmd || (num_cmd && searched) ? (
                    <div>
                        <TopPanel   
                            title={props.isLog ? "Tracking" : `Tracking commande N° ${num_cmd}`} 
                            searchHandler={props.isLog ? handleSearch : null}
                            searchPlaceholder={props.isLog ? "Rechercher un tracking par NUM_CMD_CLIENT" : null}
                            searchDefaultValue={props.isLog ? num_cmd : null}
                        />
                        {
                            !searched ? (
                                <h2 className={classes.numberCmd}>Utilisez la barre de recherche pour chercher un tracking...</h2>
                            ) : null
                        }
                    </div>
                ) : (
                    <>
                        <h1 className={classes.titlePage}>
                            Commande <span>N°{numOrder}</span>
                        </h1>
                        {
                            detailCommandeItems.length > 0 ?
                            <div className={classes.collapseContainer}>
                                <div className={open ? classes.collapse_heading_open : classes.collapse_heading} onClick={()=>setOpen(!open)}>
                                    {open ?
                                        <ChevronUp className={classes.chevron}/>
                                    : <ChevronDown className={classes.chevron}/>}
                                    <h2 className={classes.title}>Détails de la commande</h2>
                                </div> 
                                <Collapse isOpened={open}>
                                    <div className={classes.collapse_content}>
                                        {
                                            detailCommandeItems.map((product) => {
                                                return(
                                                    <p>{product}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </Collapse>
                            </div>
                            : null
                        }
                        {/* <h2 className={classes.numberCmd}>Numéro de la commande : <span>{numOrder}</span></h2> */}
                    </>
                )
            }
            {
                loading ? 
                    <Loader label="Recherche en cours"/>
                :
                <>
                    {Tracking}
                    <div className={classes.containerAlignRight}>
                        <div className={classes.blockInfo}>
                            <div>
                                <img src={Send} />
                                <div>
                                    <h4>SERVICE CLIENT</h4>
                                    <p><span>LUN-VEN 8H30/12H30 - 13H30/17H</span></p>
                                </div>
                            </div>
                            <div>
                                <p>Par téléphone : <a href="tel:0351650334">03 51 65 03 34</a></p>
                                <p>Par mail : <a href="mailto:service-client@stti.fr">service-client@stti.fr</a></p>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}