import classes from './trackingItem.module.css';
import DefaultCard from '../../components/Cards/defaultCard';
import moment from 'moment';
import Warning from '../../assets/images/warning.svg'

export default function TrackingItem(props){
    const {item,isLast,order} = props;
    const date = item.date_situation
    const date_saisie = item.date_saisie
    const type = item.type_evt;
    const code = item.code_evt;
    const code_activite = item.code_activite;

    const DataItem = (props) =>{
        const {libelle,children} = props;
        return(
            <>
                <div className={classes.data_item}>
                    <strong>{libelle}</strong>
                    <p>
                        {children}
                    </p>
                </div> 
            </>
        )
    }

    return(
        <div className={classes.root}>
            <div className={classes.bar_root}>
                <span className={isLast ? classes.bar_last : classes.bar}/>
            </div>
            <div className={classes.date}>
                {moment(date_saisie).format('DD/MM/YYYY')}
            </div>
            <div className={classes.card_root}>
                <DefaultCard style={{paddingRight : '6rem',display:'grid',gap:'1rem'}}>
                    {
                        code === "CRE" && code_activite === "RLAD" ?
                            <span className={classes.badge_type}><img src={Warning} /></span>
                        : null
                    }
                    {
                        code === "VAL"?
                            <span className={classes.badge_type}><img src={Warning} /></span>
                        : null
                    }
                    
                    <ul className={classes.data_item_container}>
                        {
                            code === "CRE" ?
                                code_activite === "RLAD" ? 
                                    <DataItem libelle="Prise en compte de la commande.">
                                        Votre commande sera livrée en chariot embarqué. Merci de vous assurer de la faisabilité de la livraison. 
                                        <a href="https://www.youtube.com/watch?v=_y5LmnrwLJc" style={{textDecoration: 'underline'}}>Lien vidéo</a>
                                    </DataItem>
                                : 
                                    <DataItem libelle="Prise en compte de la commande."></DataItem>
                            : null
                        }
                        {
                            code === "VAL" ?
                                <DataItem libelle={code_activite === "RLAD" || code_activite === "SMAL" || code_activite === "" ? "Commande expédiée." : code_activite.slice(0,3) === "MES" ? "Commande expédiée. Livraison prévue sous 3-5 jours." : null}>
                                    Merci de bien contrôler l'état de la marchandise ainsi que le nombre de colis livrés en présence de notre chauffeur et de signifier tout manquant ou casse sur la lettre de voiture.Toute réclamation faite après la livraison ne pourra être prise en charge.
                                </DataItem>
                            : null
                        }
                        {
                            code === "PRE" ?
                                <DataItem libelle="Commande en cours de préparation par nos services logistiques.">
                                    Nous allons confier votre commande à l'un de nos partenaires pour effectuer la livraison.<br/>
                                    Il vous contactera par SMS ou téléphone pour la prise de rdv.
                                </DataItem>
                            : null
                        }
                        {
                            code === "DIF" ? 
                                <DataItem libelle={item.code_justification === 'DAF' ? 
                                    `RDV refusé pour une livraison le ${moment(date).format('DD/MM/YYYY')}`:
                                    `RDV pris pour une livraison le ${moment(date).format('DD/MM/YYYY')}`
                                }>
                                </DataItem>
                            : null
                        }
                        {
                            code === "LIV" ?
                                <DataItem libelle={`Commande livrée le ${moment(date).format('DD/MM/YYYY')}`}>
                                </DataItem>
                            : null
                        }
                        {
                            code === "AAR" ?
                                <DataItem libelle={`Arrivé à nos quais le ${moment(date).format('DD/MM/YYYY')}`}>
                                </DataItem>
                            : null
                        }
                    </ul>
                </DefaultCard>
            </div>
        </div>
    )
}