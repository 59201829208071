import React,{useState,useEffect, useMemo} from 'react';
import { useParams } from 'react-router-dom'
import {fetchDetails, fetchApi} from '../../utils/fetchApi';
import classes from './orderDetails.module.css';
import Loader from '../../components/Loader';
import OrderSection from './orderSection';
import { Link } from 'react-router-dom';
import { createStatusTracking } from "../../utils/functions";
import PictoReturn from '../../assets/images/Retour.png';
import PictoDoss from '../../assets/images/Dossier.png';
import TrackingStep from '../Tracking/trackingStep';
import moment from 'moment';
import _ from 'lodash';

function OrderDetails(props){
    const {id} = useParams();
    const [order, setOrder] = useState(null)
    const [typeOrder,setTypeOrder] = useState(null);
    const [statusTrackingIsLoaded,setStatusTrackingIsLoaded] = useState(false);
    
    const [rupture, setRupture] = useState(false)

    const [statusTracking, setStatusTracking] = useState({
        prise_compte: {
            status: false,
            date: null
        },
        rdv: {
            status: false,
            date: null
        },
        prep: {
            status: false,
            date: null
        },
        expedition: {
            status: false,
            date: null
        },
        livraison: {
            status: false,
            date: null
        },
        aar: {
            status: false,
            date: null
        }
    });
    const getOrder = async() =>{
        let order = null
        if (id){
            order = await fetchDetails(id);
            setOrder(order)
        }
        
    }   
    
    const getRupture = async (numCmd) => {
        try {
            if (!numCmd) {
                setRupture([])
            }
            var data = await fetchApi(`stti/rupture/order/${numCmd}`, null, 'GET')
            setRupture(data)
        } catch (error) {
            setRupture([])
        }
    }

    useEffect(() => {
        props.checkLogin()
        
        getOrder();
    },[])
    
    useEffect(()=>{
        if (order){
            createStatusTracking(order, statusTracking, setTypeOrder, setStatusTracking, setStatusTrackingIsLoaded);
            getRupture(order.numCmdClient)
        }
    },[order])

    const OrderSections = useMemo(()=>{
        if (order) {
            return(
                <>
                    <OrderSection key={`parent-client`} identifier={'CLIENT'} datas={order} />
                    <OrderSection key={`parent-livraison`} identifier={'LIVRAISON'} datas={order} />
                    <OrderSection key={`parent-information`} identifier={'INFORMATIONS'} datas={order} />
                    {
                        (order.sttiFlatArticles) ?
                        <OrderSection key={`parent-logistique`} identifier={'LOGISTIQUE'} datas={order} rupture={rupture} />
                        : null
                    }
                    
                    <OrderSection key={`parent-chargement`} identifier={'CHARGEMENT'} datas={order} />
                </>
            )
        } else {
            return null
        }
    }, [order, rupture])

    let numDossier = '-';
    let dateCreation = '-';
    if (order){
        if (order?.numDossierTrans)
            numDossier = order?.numDossierTrans
        if (order.createdAt)
            dateCreation = moment(order.createdAt).format('DD-MM-YYYY')
    }

    return(
        <div className={classes.root}>
            <div className='infos'>
                <a className='return' href='/orders'><img className='picto_return' src={PictoReturn} />Retour aux résultats de recherche</a>
                <p className='left'>
                    <span className='titleYellowBis'>Numéro de commande </span> n°{order?.numCmdClient} /&nbsp; <span className='titleYellowBis'>Numéro de dossier </span> n°{numDossier} /&nbsp; <span className='titleYellowBis'>Date de création </span> {dateCreation} {order ? <Link className={classes.picto_doss_container} to={`/tracking/${order?.urlTracking}`}><img className='picto_doss' src={PictoDoss} /></Link> : null}</p>
            </div>
            {(order && rupture !== false) ? (
                <div className="containerSection">
                    {OrderSections}
                    <div className={classes.heading_open} >
                        <h2 className={classes.title}>Historique tracking</h2>
                    </div>
                    <Link className={classes.link} to={`/tracking/${order?.urlTracking}`}>
                        {
                            statusTrackingIsLoaded ? <TrackingStep statusTracking={statusTracking} typeOrder={typeOrder} />
                            : <Loader></Loader>
                        }
                        
                    </Link>
                </div>
            )  : <Loader label="Chargement en cours" />}        
        </div>
    )
}

export default OrderDetails;